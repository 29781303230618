.home-hero {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 20px;
    margin-top: 125px;
    margin-bottom: 175px;
    width: 75%;
}

.home-hero-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.home-hero-info > h1 {
    font-size: 5rem;
    font-weight: 700;
    margin: 0;
}

.home-hero-info > span {
    font-size: 1.7rem;
    font-weight: 700;
    color: rgb(160, 160, 160);
    text-decoration: underline var(--blue);
}

.home-hero-info > div {
    font-size: 2rem;
    width: 90%;
}

.home-hero-img {
    width: 90%;
    height: 0;
    padding-bottom: 90%; 
    overflow: hidden;
    position: relative;
}

.home-hero-img>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 90% 10%;
    position: absolute;
    top: 0; 
    left: 0; 
}

.home-about {
    background-color: var(--blue);
    color: white;
    padding: 25px 0 40px;
    margin-bottom: 75px;
}

.home-about > .container {
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 25px;
}

.home-about h1, .home-projects h1, .home-contact h1 {
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
    margin: 0;
}

.home-about p {
    font-size: 1.5rem;
    font-weight: 500;
}

.home-projects .container {
    margin: 25px auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: 35px;
    width: 75%;
}

.home-projects {
    margin-bottom: 75px;
}

.home-contact .container {
    width: 75%;
}

@media (max-width: 991.8px) {
    .home-hero {
        grid-template-columns: 1fr;
        margin-top: 35px;
        margin-bottom: 100px;
        width: 90%;
    }

    .home-hero-img {
        width: 100%;
        padding-bottom: 100%; 
    }
    
    .home-about > .container {
        width: 85%;
    }

    .home-projects .container {
        grid-template-columns: 1fr;
        width: 85%;
    }

    .home-contact .container {
        width: 90%;
    }

    .home-about h1, .home-projects h1, .home-contact h1 {
        font-size: 3rem;
    }
}

@media (max-width: 576px) {
    .home-hero-info > h1 {
        font-size: 3rem;
    }

    .home-hero-info > span {
        font-size: 1.3rem;
    }
    
    .home-hero-info > div {
        font-size: 1.5rem;
        width: 90%;
    }

    .home-about p {
        font-size: 1.3rem;
        font-weight: 500;
    }
}