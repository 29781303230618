.projects-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projects-wrap h1 {
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
    margin-top: 50px;
}

.projects-wrap span {
    text-align: center;
    font-weight: 600;
    color: rgb(160, 160, 160);
}

.projects-wrap .container {
    margin: 75px auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: 35px;
    width: 75%;
}

.projects-wrap {
    margin-bottom: 75px;
}

@media (max-width: 991.8px){
    .projects-wrap h1 {
        font-size: 3rem;
    }

    .projects-wrap .container {
        grid-template-columns: 1fr;
        width: 90%;
        margin: 50px auto;
    }
}