:root {
  --black: #00171f;
  --blue: #00a7e1;
}

html {
  font-size: 14px;
}

a {
  text-decoration: none !important;
}

body {
  margin: 0;
}

.button-blue {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue);
  color: white;
  width: fit-content;
  min-width: 100px;
  padding: 7px 12px;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  transition: 0.25s ease;
}

.button-blue:hover {
  background-color: #068ab6;
}

.button-outline-white {
  color: white;
  border: 2px solid white;
  width: fit-content;
  padding: 7px 12px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  align-self: center;
  transition: 0.25s ease;
}

.button-outline-white:hover {
  background-color: white;
  color: black;
}