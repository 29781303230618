body {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
}

.card .card-title, .card .card-text {
    text-decoration: none;
}

.card {
    transition: 0.25s ease;
    height: 100%;
}
.card:hover {
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.3);
}

.card-img-top {
    height: 75%;
}

.card-title.h5 {
    font-size: 1.5rem;
    font-weight: 700;
}

.card .card-img-top {
    object-fit: cover;
}

footer {
    background-color: var(--blue);
    color: white;
    margin-top: 50px;
    padding: 15px;
}

footer .container {
    display: flex;
    justify-content: space-between;
}

footer h4 {
    font-weight: 700;
    color: white;
}

footer .socials {
    display: flex;
    gap: 20px; 
}

footer .social-icon {
    color: white;
    height: 24px;
    width: 24px;
}

footer .links {
    display: flex;
    justify-content: space-evenly;
}

.footer-link {
    color: white;
    text-decoration: none;
    padding: 0 20px;
    font-size: 1.2rem;
    font-weight: 600;
}

@media (max-width: 576px){
    footer .container {
        flex-direction: column-reverse;
        gap: 20px;
        justify-content: center;
        align-items: center;
    }

    .footer-link {
        font-size: 1rem;
        padding: 0 10px;
    }
}